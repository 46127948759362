export const styles = {
  global: (props) => ({
    "html, body": {
      padding: 0,
      margin: 0,
      height: "100%",
      fontFamily: `-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
        Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif`,
      fontSize: "16px",
    },
  }),
};
